<template>
    <el-header id="nav" class="shadow">
        <i @click="$emit('toggle-burger')" class="burger el-icon-more"></i>
        <router-link to="/">
            <img src="@/assets/WTA-Tour_S_positive_purple_RGB.png" class="brand-logo" alt="brand logo" width="100%" height="100%" />
        </router-link>
        <router-link class="title" to="/">Baseline Core </router-link>
        <span class="spacer"></span>
        <span class="production-indicator production" v-if="mode === 'production'">Production Mode</span>
        <span class="production-indicator review" v-if="mode === 'review'">Review Mode</span>
        <span class="production-indicator development" v-if="mode === 'development'">Development Mode</span>
        <span class="production-indicator local" v-if="mode === 'local'">Local Mode</span>

        <a href="#" @click="userLogout" title="Logout" class="logout-button">
            <svg
                class="svg-icon"
                style="width: 1em; height: 1em; vertical-align: middle; fill: currentColor; overflow: hidden"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M554.666667 128l-85.333333 0 0 426.666667 85.333333 0L554.666667 128zM760.746667 220.586667l-60.586667 60.586667C767.573333 335.36 810.666667 418.56 810.666667 512c0 165.12-133.546667 298.666667-298.666667 298.666667s-298.666667-133.546667-298.666667-298.666667c0-93.44 43.093333-176.64 110.08-231.253333L263.253333 220.586667C180.48 290.986667 128 395.093333 128 512c0 212.053333 171.946667 384 384 384 212.053333 0 384-171.946667 384-384C896 395.093333 843.52 290.986667 760.746667 220.586667z"
                />
            </svg>
        </a>
    </el-header>
</template>

<script>
import { logout } from './../services/authService';

export default {
    data() {
        return {
            mode: process.env.VUE_APP_APPLICATION_MODE,
            currentUrl: this.$route.path,
        };
    },
    methods: {
        navigate(url) {
            this.$router.push(url);
        },
        menuClick(command) {
            switch (command) {
                case 'profile':
                    this.$router.push('/profile');
                    break;
                default:
                    console.log('Unknown command: ', command);
            }
        },

        async userLogout() {
            await logout();

            const channel = new BroadcastChannel('auth_channel');
            channel.postMessage('logout');
        },
    },
};
</script>

<style lang="scss">
#nav {
    & .el-menu-item {
        font-size: 16px;

        &.is-active {
            background-color: rgba(0, 0, 0, 0.2) !important;
        }
    }

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    height: 70px !important;

    .brand-logo {
        width: 150px;
    }
}

.title {
    color: #2d0046;
    font-size: 20px;
    font-weight: 600;
    padding: 16px;
    text-decoration: none !important;
}

.burger {
    color: white;
    cursor: pointer;
    margin-right: 8px;
    transform: rotateZ(90deg);
    font-size: 18px;
    padding: 8px;
    border-radius: 50px;

    &:hover {
        background: rgba(0, 0, 0, 0.25);
    }
}

.production-indicator {
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 5px;
}

.production-indicator.production {
    background-color: white;
    color: red;
}

.production-indicator.review {
    background-color: white;
    color: orange;
}

.production-indicator.development {
    background-color: white;
    color: green;
}

.production-indicator.local {
    background-color: white;
    color: blue;
}

.logout-button {
    background-color: #2d0046;
    border-color: #2d0046;
    padding: 1px 10px 3px;
    text-align: center;
    border-radius: 4px;
    font-weight: 900;
    font-size: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

    svg {
        color: white;
    }
}
</style>
