<script>
export default {
    name: 'PlayerWildcardsStatsCard.vue',
    props: {
        title: String,
        eligible: [String, Number],
        used: Number,
        special: String
    },
    computed: {
        remaining() {
            return this.eligible === "UNLIMITED" ? "-" : this.eligible - this.used;
        },
        remainingClass() {
            return this.remaining > 1 ? "text-success" : "text-danger";
        },
        remainingIcon() {
            return this.remaining > 1 ? "el-icon-circle-check" : "el-icon-warning";
        }
    }
};
</script>

<template>
    <div>
        <el-card shadow="always">
            <h3 class="card-title">{{ title }}</h3>
            <p>Eligible: <span class="text-success">{{ eligible }}</span></p>
            <p>Used: <span>{{ used }}</span></p>

            <p v-if="remaining !== '-'" class="remaining">
                Remaining: <span :class="remainingClass">{{ remaining }}</span>
                <i :class="['el-icon', remainingIcon]"></i>
            </p>

            <el-tag v-if="special" type="info" class="special-badge">{{ special }}</el-tag>
        </el-card>
    </div>
</template>

<style scoped>
.card-title {
    font-size: 16px;
    color: #555;
}

.remaining {
    display: flex;
    align-items: center;
}

.text-success {
    color: #67C23A;
}

.text-danger {
    color: #F56C6C;
}

.special-badge {
    margin-top: 10px;
    display: inline-block;
}

p {
    font-size: 12px;
    color: #444;
    display: block;
    padding-left: 8px;
}
</style>