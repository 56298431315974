<script>
import { mapActions } from 'vuex';
import PlayerWildcardsStatsCard from './PlayerWildcardsStatsCard.vue';
import PlayerWildcardsStatsDetails from '@/components/player-wildcards/PlayerWildcardsStatsDetails.vue';

export default {
    name: 'PlayerWildcardsStats',
    components: { PlayerWildcardsStatsDetails,  PlayerWildcardsStatsCard },
    props: {
        playerId: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            playerData: null
        };
    },
    watch: {
        playerId: 'fetchPlayerData',
        year: 'fetchPlayerData'
    },
    created() {
        this.fetchPlayerData();
    },
    methods: {
        ...mapActions('tournamentWildcards', ['loadTournamentWildcardsByPlayer']),
        async fetchPlayerData() {
            await this.loadTournamentWildcardsByPlayer({ year: this.year, playerId: this.playerId });
            this.playerData = this.$store.state.tournamentWildcards.tournamentWildcardsByPlayer;
        },
        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
    },
};
</script>

<template>
    <div class="player-wildcards-stats">
        <div v-if="playerData" class="dynamic-control">
            <PlayerWildcardsStatsDetails />
            <h3>Wildcards used in tournaments from this year</h3>
            <el-table :data="playerData.tournamentsFromThisYear" style="width: 100%">
                <el-table-column prop="tournamentName" label="Tournament Name"></el-table-column>
                <el-table-column prop="tournamentSponsor" label="Sponsor"></el-table-column>
                <el-table-column prop="eventTypeCode" label="Event Type" ></el-table-column>
                <el-table-column prop="location" label="Location"></el-table-column>
                <el-table-column prop="startDate" label="Start Date">
                    <template slot-scope="scope">
                        {{ formatDate(scope.row.startDate) }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<style scoped>
label {
    font-size: 16px;
    color: #444;
    display: block;
    padding-left: 8px;
    padding-bottom: 16px;
    padding-right: 16px;
    font-weight: bold;
}

h2 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
}

h3 {
    font-size: 16px;
    color: #555;
}

p {
    font-size: 12px;
    color: #444;
    display: block;
    padding-left: 8px;
}

.player-wildcards-stats {
    margin-top: 40px;
}

.dynamic-control {
    padding-right: 16px;
    align-self: flex-start;
    overflow: hidden;
}

.title-el-divider {
    font-size: 16px;
    color: #444;
    font-weight: bold;
}
</style>