<template>
    <div>
        <el-dialog
            :visible.sync="editDialogVisible"
            :close-on-click-modal="false"
            :title="mode === 'edit' ? 'Edit player bonus' : 'Add player bonus'"
            width="65%"
        >
            <FormGroup :model="selectedPlayerBonus" :schema="playerBonusesSchema" :layout="layout" @change="onChange" />
            <div class="dialog-footer">
                <el-button
                    type="primary"
                    @click="saveChanges"
                    :disabled="!selectedPlayerBonus || !selectedPlayerBonus.isValid() || !selectedPlayerBonus.hasChanges()"
                    >Save changes</el-button
                >
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>
                <el-button v-if="mode === 'edit' && !isReadOnlyAccess" type="danger" plain @click="deletePlayerBonusClick">Delete player bonus</el-button>
            </div>
        </el-dialog>

        <div class="flex-row mt-0">
            <span class="spacer"></span>
            <el-button v-if="!isReadOnlyAccess" @click="addPlayerBonus" plain type="primary"> Add player bonus </el-button>
            <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DataTable
            v-if="!loadingError"
            v-loading="loadingPlayerBonuses"
            :items="playerBonuses"
            :fields="['playerId', 'bonusYear', 'bonusType', 'bonusDate', 'bonusSingles', 'bonusDoubles']"
            :schema="playerBonusesSchema"
            @item-clicked="itemClicked"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerBonus } from '../models/player-bonus';
import moment from 'moment';
import FileSaver from 'file-saver';
import { isReadOnlyAccess } from '../services/authGroups';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';

export default {
    props: ['playerId'],
    data() {
        return {
            playerBonusesSchema: PlayerBonus.schema,
            layout: PlayerBonus.layout,
            editDialogVisible: false,
            selectedPlayerBonus: null,
            mode: null,
            requestError: null,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('playerBonuses', ['playerBonuses', 'loadingPlayerBonuses', 'loadingError']),
    },
    methods: {
        exportCsvClick() {
            this.exportCsv({ playerId: this.playerId })
                .then((result) => {
                    const file = new File([result], `player_bonuses_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        ...mapActions('playerBonuses', ['findPlayerBonuses', 'createPlayerBonus', 'updatePlayerBonus', 'deletePlayerBonus', 'exportCsv']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerBonuses,
                options: this.playerId,
                dtosName: 'Player Bonusses',
            };
            retrieveDtos(params);
        },
        itemClicked(item) {
            this.selectedPlayerBonus = new PlayerBonus(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        addPlayerBonus() {
            this.selectedPlayerBonus = new PlayerBonus({
                playerId: this.playerId,
            });
            this.mode = 'add';
            this.editDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createPlayerBonus,
                    dto: this.selectedPlayerBonus,
                    dtoName: 'Player Bonus',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            } else {
                let params = {
                    self: this,
                    fUpdate: this.updatePlayerBonus,
                    dto: this.selectedPlayerBonus,
                    dtoName: 'Player Bonus',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            }
        },
        onChange() {},
        deletePlayerBonusClick() {
            let params = {
                self: this,
                fDelete: this.deletePlayerBonus,
                vConfirm: this.playerId,
                dto: this.selectedPlayerBonus,
                dtoName: 'Player Bonus',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        clearError() {
            this.requestError = null;
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
    },
};
</script>

<style></style>
