<template>
    <div v-loading="savingPlayer && !isReadOnlyAccess">
        <div v-if="player">
            <ErrorDisplay :requestError="this.requestError" @closed="clearError" />
            <div class="flex-row mt-0 mb-32">
                <h2><i class="el-icon-user mr-16"></i>Adding new Player</h2>
                <span class="spacer"></span>
                <el-button @click="closePlayerAdd" plain type="primary">Close </el-button>
            </div>
            <el-tabs value="general">
                <el-tab-pane label="General" name="general">
                    <FormGroup :model="player" :schema="schema" :layout="layout" @change="onChange" v-loading="savingPlayer" />
                </el-tab-pane>
            </el-tabs>

            <SaveChanges :model="player" @save="saveChanges" @discard="discardChanges" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as _ from 'lodash';
import { Player } from '../models/player';
import moment from 'moment';
import { isReadOnlyAccess } from '../services/authGroups';

export default {
    data() {
        return {
            schema: Player.schema,
            layout: Player.layoutAddPlayer,
            requestError: null,
            player: null,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.player = new Player();
        this.player.data.inactive = 'A';
    },
    computed: {
        ...mapGetters('playerDetails', ['savingPlayer']),
    },
    methods: {
        ...mapActions('playerDetails', ['createPlayer']),
        saveChanges() {
            this.createPlayer(this.player)
                .then((result) => {
                    this.$router.push(`/players/${result.id}`);
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        clearError() {
            this.requestError = null;
        },
        discardChanges() {
            this.player.discardChanges();
        },
        closePlayerAdd() {
            this.$router.push(`/players/`);
        },
        onChange() {},
    },
};
</script>
