import axios from "axios";

export default {
    namespaced: true,
    state: {
        playerEntryHistoryData: {},
        loadingPlayerEntryHistoryData: false,
        loadingError: false,
        downloadingPdf: false,
        savingWithdrawalReason: false,
        savingError: null,
    },
    getters: {
        getDownloadingPdf: (state) => state.downloadingPdf,
        getPlayerEntryHistoryData: (state) => state.playerEntryHistoryData,
        getLoadingPlayerEntryHistoryData: (state) => state.loadingPlayerEntryHistoryData,
        getLoadingError: (state) => state.loadingError,
        savingWithdrawalReason: (state) => state.savingWithdrawalReason,
        savingError: (state) => state.savingError,
    },
    mutations: {
        setPlayerEntryHistoryData(state, data) {
            state.playerEntryHistoryData = data;
        },

        startLoadingPlayerEntryHistoryData(state) {
            state.loadingPlayerEntryHistoryData = true;
        },

        stopLoadingPlayerEntryHistoryData(state) {
            state.loadingPlayerEntryHistoryData = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startPlayerEntryHistoryPdf(state) {
            state.downloadingPdf = true;
        },

        stopPlayerEntryHistoryPdf(state) {
            state.downloadingPdf = false;
        },

        startSavingWithdrawalReason(state) {
            state.savingWithdrawalReason = true;
        },

        stopSavingWithdrawalReason(state) {
            state.savingWithdrawalReason = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },
    },

    actions: {
        async findPlayerEntryHistory(context, options = {}) {            
            context.commit('startLoadingPlayerEntryHistoryData');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/${options.tournamentId}/${options.season}/Player/${options.playerId}`, {
                    params: {
                        pageSize: options.options.pageSize || 20,
                        sortBy: options.options.sortBy || '',
                        sortDir: options.options.sortDir === 'descending' ? -1 : 1,
                        page: options.options.page,
                        isWithdrawal : options.options.isWithdrawal || false,                      
                        isEntryHistory : options.options.isEntryHistory || false,
                        queryString: options.options.queryString || ''                       
                    },
                });
                context.commit('stopLoadingPlayerEntryHistoryData');
                context.commit('setPlayerEntryHistoryData', result.data);                
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerEntryHistoryData');
                return Promise.reject(error);
            }
        },
        async getPlayerEntryHistoryPdf(context, request = {}) {
            context.commit('startPlayerEntryHistoryPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/${request.season}/Player/${request.playerId}/Pdf`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopPlayerEntryHistoryPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopPlayerEntryHistoryPdf');
            }
        },
        async getWithdrawalReasonPdf(context, request = {}) {
            context.commit('startPlayerEntryHistoryPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/${request.tournamentId}/${request.season}/Player/${request.playerId}/WithdrawalReason/Pdf?tournamentName=${request.tournamentName || ''}`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopPlayerEntryHistoryPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopPlayerEntryHistoryPdf');
            }
        },
        async saveWithdrawalReason(context, withdrawalReason) {            
            context.commit('startSavingWithdrawalReason');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/EntryHistory/Edition/UpdateWithdrawalReason`, withdrawalReason.data);
                context.commit('clearLoadingError');
                context.commit('stopSavingWithdrawalReason');
                // context.commit('setSysCode', new SysCode(flatten(result.data)));                
                return Promise.resolve(withdrawalReason.data);
            } catch (error) {
                context.commit('stopSavingWithdrawalReason');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },
    }
}