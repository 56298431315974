import axios from "axios";

export default {
    namespaced: true,
    state: {
        playerEntryAndWithdrawalHistoryData: {},
        loadingPlayerEntryAndWithdrawalHistoryData: false,
        loadingError: false,
        downloadingPdf: false,
        savingWithdrawalReason: false,
        savingError: null,
    },
    getters: {        
        getPlayerEntryAndWithdrawalHistoryData: (state) => state.playerEntryAndWithdrawalHistoryData,
        getLoadingPlayerEntryAndWithdrawalHistoryData: (state) => state.loadingPlayerEntryAndWithdrawalHistoryData,
        getLoadingError: (state) => state.loadingError,        
        savingError: (state) => state.savingError,
    },
    mutations: {
        setPlayerEntryAndWithdrawalHistoryData(state, data) {
            state.playerEntryAndWithdrawalHistoryData = data;
        },

        startLoadingPlayerEntryAndWithdrawalHistoryData(state) {
            state.loadingPlayerEntryAndWithdrawalHistoryData = true;
        },

        stopLoadingPlayerEntryAndWithdrawalHistoryData(state) {
            state.loadingPlayerEntryAndWithdrawalHistoryData = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },       
    },

    actions: {
        async findPlayerEntryAndWithdrawalHistory(context, options = {}) {                    
            context.commit('startLoadingPlayerEntryAndWithdrawalHistoryData');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/v2/EntryHistory/Edition/PlayerEntryAndWithdrawalsHistory/Player/${options.playerId}`, {
                    params: {
                        pageSize: options.options.pageSize || 20,
                        sortBy: options.options.sortBy || '',
                        sortDir: options.options.sortDir === 'descending' ? -1 : 1,
                        page: options.options.page,
                        isWithdrawal : options.options.isWithdrawal || false,                      
                        isEntryHistory : options.options.isEntryHistory || false,
                        eventTypeCode : options.options.eventTypeCode || '',
                    },
                });
                context.commit('stopLoadingPlayerEntryAndWithdrawalHistoryData');
                context.commit('setPlayerEntryAndWithdrawalHistoryData', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerEntryAndWithdrawalHistoryData');
                return Promise.reject(error);
            }
        },        
    }
}