import Vue from 'vue'; // eslint-disable-line no-use-before-define
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const instrumentationKey = process.env.VUE_APP_INSTRUMENTATION_KEY;

let appInsights = null;

if (instrumentationKey) {
    appInsights = new ApplicationInsights({
        config: {
            connectionString: `InstrumentationKey=${instrumentationKey}`,
            enableAutoRouteTracking: true,
            loggingLevelConsole: 2,
        },
    });

    appInsights.loadAppInsights();
    console.log('Application Insights initialized');

    Vue.config.errorHandler = (err, vm, info) => {
        appInsights.trackException({
            error: err,
            properties: { info },
        });
    };

    window.addEventListener('error', (event) => {
        appInsights.trackException({ error: event.error });
    });

    window.addEventListener('unhandledrejection', (event) => {
        appInsights.trackException({ error: event.reason });
    });
} else {
    console.warn('Application Insights disabled: No instrumentation key found.');
}

export default appInsights;
