<template>
    <div>
        <table class="procedure" :id="'table' + weekType" v-loading="loading">
            <tr>
                <td colspan="2">
                    <h2>{{ procedure }} Procedure for Week {{ week }}/{{ season }}</h2>
                </td>
            </tr>
            <tr v-if="editions !== null && editions.length !== 0">
                <td style="width: 60%" class="tournaments">
                    <span v-for="edition in editions" v-bind:key="edition.id" class="commaListItem">{{ edition.toLowerCase() }}</span>
                </td>

                <td v-if="!isReadOnlyAccess">
                    <el-button
                        :disabled="editions.length === 0"
                        @click="runProcedure"
                        type="primary"
                        class="mr-16"
                        v-loading.fullscreen.lock="runningProcedure"
                        icon="el-icon-video-play"
                        >Run</el-button
                    >
                </td>
            </tr>
            <tr v-if="editions === null || editions.length === 0">
                <td>No tournaments planned.</td>
                <td></td>
            </tr>
        </table>

        <div class="ui modal" id="acceptance_list_completed" style="display: none">
            <i class="close icon"></i>
            <div class="header">Acceptance List Generation Finished</div>
            <div class="image content">
                <div class="description">
                    <div class="ui header"><p>The acceptance-lists for the chosen week have successfully been generated.</p></div>
                </div>
            </div>
            <div class="actions">
                <div class="ui positive right labeled icon button">
                    Ok
                    <i class="checkmark icon"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { getMondayOfWeek } from '../utils/date-utils';
import { isReadOnlyAccess } from '../services/authGroups';
/* eslint-disable no-magic-numbers */

export default {
    props: ['procedure', 'weekType', 'weekToGoBack'],
    data() {
        return {
            season: null,
            week: null,
            editions: [],
            comments: '',
            loading: false,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    computed: {
        ...mapGetters('procedure', ['runningProcedure']),
    },
    mounted() {
        this.refresh();
    },
    watch: {
        weekToGoBack: function (newVal, oldVal) {
            this.refresh();
        },
    },
    methods: {
        refresh() {
            this.loading = true;
            this.editions = [];
            this.comments = '';
            Date.prototype.getWeek = function getWeek() {
                var dt = new Date(this.getFullYear(), 0, 1);

                return Math.ceil(((this - dt) / 86400000 + dt.getDay() + 1) / 7);
            };

            const dateToBeUsed = process.env.VUE_APP_DATABASE_DATE !== undefined ? new Date(process.env.VUE_APP_DATABASE_DATE) : new Date();

            this.season = dateToBeUsed.getFullYear();
            this.week = dateToBeUsed.getWeek();

            if (this.weekToGoBack > 0) {
                this.week = this.week - this.weekToGoBack;
            }

            switch (this.weekType) {
                case 'twoweek':
                    this.week += 2;

                    break;
                case 'threeweek':
                    this.week += 3;

                    break;
                case 'fourweek':
                    this.week += 4;

                    break;
                case 'sixweek':
                    this.week += 6;

                    break;
            }

            axios.get(`${process.env.VUE_APP_API_URL}/Edition/week/${this.season}/${this.week}`).then((src) => {
                this.comments = '';
                this.loading = false;
                src.data.forEach((item) => {
                    if (
                        (this.weekType === 'sixweek' && item.level !== 'Challenger') ||
                        (this.weekType === 'twoweek' && item.level !== 'Challenger' && item.level !== 'GrandSlam') ||
                        (this.weekType === 'fourweek' && item.level === 'GrandSlam') ||
                        (this.weekType === 'threeweek' && item.level !== 'GrandSlam')
                    ) {
                        this.editions.push(item.name);
                        this.comments = item.name;
                    }
                });
            });
        },
        runProcedure() {
            if (this.runningProcedure) {
                this.$alert('Another procedure is already running. Please wait until it is finished and try again afterwards.', {
                    type: 'error',
                }).then(() => {
                    return;
                });
            } else {
                var procedureName = '';

                if (this.weekType === 'twoweek') {
                    procedureName = 'doubles-procedure';
                } else if (this.weekType === 'threeweek') {
                    procedureName = 'quali-procedure';
                } else if (this.weekType === 'fourweek') {
                    procedureName = 'grand-slam-procedure';
                } else if (this.weekType === 'sixweek') {
                    procedureName = 'main-procedure';
                }

                this.$confirm(
                    'You are about to run the ' +
                        this.procedure +
                        ' procedure for week ' +
                        this.week +
                        '. This will generate accepted entries for all tournaments concerned. Please confirm.',
                    this.procedure + ' procedure',
                    {
                        confirmButtonText: 'Run ' + this.procedure + ' procedure',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                    },
                ).then(() => {
                    this.$store.commit('procedure/procedureStarted');
                    axios({
                        method: 'post',
                        url: `${process.env.VUE_APP_API_URL}/AcceptanceList/${this.season}/${this.week}/${procedureName}`,
                    })
                        .then(() => {
                            this.$store.commit('procedure/procedureFinished');
                            this.$message('Execution of the ' + this.procedure + ' procedure was completed successfully.');
                        })
                        .catch(() => {
                            this.$store.commit('procedure/procedureFinished');
                            this.$alert('The procedure-call failed. Please get into contact with WTA Tech to investigate this issue.', {
                                type: 'error',
                            });
                        });
                });
            }
        },
    },
};
</script>
<style>
.tournaments span {
    text-transform: capitalize;
}
.tournaments span:not(:last-child)::after {
    content: ', ';
}
table.procedure {
    width: 40em;
}
table.procedure tr td:first-child {
    width: 20em;
}
</style>
