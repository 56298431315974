<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Required Tournament"
            :selected="selectedRequired"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Required Tournament"
            :selected="selectedRequired"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteRequired"
            @close="editDialogVisible = false"
        />

        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Required Tournament {{ tournament != null ? tournament.name + ', ' + tournament.season : '' }}</h2>
            <span class="spacer"></span>
        </div>

        <DetailedInformation screenName="Required Tournaments" :informationArray="this.informationArray()" />

        <div>
            <el-button v-if="!isReadOnlyAccess" @click="addPlayersByCutoffRank" class="mr-16 add-player-btn">Add Players by Cutoff Rank</el-button>
            <el-date-picker
                v-model="rankingDate"
                :firstDayOfWeek="1"
                type="week"
                format="yyyy-MM-dd"
                placeholder="Ranking Date"
                class="mr-16 inline"
            ></el-date-picker>
            <el-input v-model="cutoffRank" placeholder="Cutoff Rank" class="inline input mr-16 mb-8"></el-input>
        </div>

        <div><el-button v-if="!isReadOnlyAccess" @click="addPlayersByMainDraw" class="add-player-btn mr-16 mb-8">Add Players by Main Draw</el-button></div>

        <div><el-button v-if="!isReadOnlyAccess" @click="addPlayerByHand" class="add-player-btn mr-16">Add Player by Hand</el-button></div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" class="mt-16" />

        <div>
            <DataTable
                :items="players.data"
                :schema="schema"
                :fields="['playerId', 'fullName', 'playerSinglesRank', 'playerNationality', 'tournamentCategoryM']"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                @header-clicked="setSort"
                @item-clicked="selectRequired"
                :autoSort="false"
            />

            <Paginator
                v-if="players.data"
                :data="players"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RequiredTournament } from '../../models/required-tournament';
import { getMondayOfWeek } from '../../utils/date-utils';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';
import { isReadOnlyAccess } from '../../services/authGroups';

export default {
    data() {
        return {
            id: this.$route.params.id,
            year: this.$route.params.year,
            schema: RequiredTournament.schema,
            schemaAdd: RequiredTournament.schemaAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            selectedRequired: null,
            layout: RequiredTournament.layout,
            layoutAdd: RequiredTournament.layoutAdd,
            rankingDate: null,
            cutoffRank: null,
            requestError: null,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        let params = {
            season: this.year,
            tournamentId: this.id,
        };
        this.retrieveTournament(params);
        this.retrieve();
    },
    computed: {
        ...mapGetters('requiredTournamentPlayerList', ['players', 'tournament', 'loadingPlayers', 'loadingPlayersError', 'options']),
    },
    methods: {
        ...mapActions('requiredTournamentPlayerList', [
            'retrievePlayers',
            'retrieveTournament',
            'insertPlayerByHand',
            'insertPlayersByRank',
            'insertPlayersByMainDraw',
            'updateRequiredTournament',
            'deletePlayerByTournament',
        ]),
        refresh() {
            this.retrieve();
        },
        retrieve() {
            let fParams = this.options;
            fParams.season = this.year;
            fParams.tournamentId = this.id;
            let params = {
                self: this,
                fRetrieve: this.retrievePlayers,
                options: fParams,
                dtosName: 'Special Rankings',
            };
            retrieveDtos(params);
        },
        addPlayerByHand() {
            this.selectedRequired = new RequiredTournament();
            this.selectedRequired.data.season = this.year;
            this.selectedRequired.data.tournamentId = this.id;
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        addPlayersByMainDraw() {
            let params = this.options;
            params.season = this.year;
            params.tournamentId = this.id;
            this.insertPlayersByMainDraw(params)
                .then((data) => {
                    this.$notify({
                        title: 'Add required tournament',
                        message: data + ' Players added',
                        type: 'success',
                        duration: 5000,
                    });
                    this.retrieve();
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        addPlayersByCutoffRank() {
            let params = this.options;
            params.season = this.year;
            params.tournamentId = this.id;
            if (this.rankingDate == null || this.cutoffRank == null) {
                this.$notify({
                    title: 'Add required tournament',
                    message: 'Please specify ranking date and cutoff rank',
                    type: 'error',
                    duration: 5000,
                });
                return;
            }
            params.rankDate = this.rankingDate;
            params.cutoffRank = this.cutoffRank;
            this.insertPlayersByRank(params)
                .then((data) => {
                    this.$notify({
                        title: 'Add required tournament',
                        message: data + ' Players added',
                        type: 'success',
                        duration: 5000,
                    });
                    this.retrieve();
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        resetPage() {
            this.options.page = 0;
            this.retrieve();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.retrieve();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.retrieve();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.retrieve();
        },
        prevPage() {
            this.options.page--;
            this.retrieve();
        },
        nextPage() {
            this.options.page++;
            this.retrieve();
        },
        onChange() {},
        selectRequired(item) {
            this.selectedRequired = new RequiredTournament(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.insertPlayerByHand,
                    dto: this.selectedRequired.data,
                    dtoName: 'Required Tournament',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            } else if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateRequiredTournament,
                    dto: this.selectedRequired.data,
                    dtoName: 'Required Tournament',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            }
        },
        deleteRequired() {
            let params = {
                self: this,
                fDelete: this.deletePlayerByTournament,
                vConfirm: this.selectedRequired.data.playerId,
                dto: this.selectedRequired,
                dtoName: 'Required Tournament',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This screen is used to display all players who need to incorporate their result into ranking.',
                'Players can be added by Cut Off Rank at specified ranking date. Singles Rank will be set to historical ranking at specified ranking date. Monday of week will be selected.',
                'Players can be added by Main Draw Entry. Singles Rank will be set to current ranking.',
                'Players can be added by Hand. Singles Rank will be set to current ranking, if not specified otherwise.',
            ];
        },
    },
};
</script>

<style scoped>
.inline {
    display: inline-block;
}
.input {
    max-width: 126px;
}
.add-player-btn {
    width: 200px;
}
.label {
    font-size: 0.75em;
    font-style: italic;
}
</style>
