<template>
    <div v-loading="savingTournament && !isReadOnlyAccess">
        <div>
            <h2 class="mt-0"><i class="el-icon-trophy-1 mr-16"></i>Add tournament</h2>
            <el-tabs value="general">
                <el-tab-pane label="General" name="general">
                    <FormGroup :model="tournament" :schema="schema" :layout="layout" @change="onChange" />
                </el-tab-pane>
            </el-tabs>

            <SaveChanges :model="tournament" @save="saveChanges" @discard="discardChanges" />

            <!-- <div v-if="tournament.hasChanges()">
                <el-button @click="saveChanges" type="primary" class="shadow" style="margin-top: 32px">
                    Save changes
                </el-button>
                <el-button @click="discardChanges" style="margin-top: 32px">
                    Discard changes
                </el-button>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as _ from 'lodash';
import { Tournament } from '../models/tournament';
import { isReadOnlyAccess } from '../services/authGroups';

export default {
    data() {
        return {
            schema: Tournament.schema,
            layout: Tournament.layout,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.findTournament();
    },
    computed: {
        ...mapGetters('tournamentDetails', ['tournament', 'savingTournament']),
    },
    methods: {
        ...mapActions('tournamentDetails', ['findTournament', 'saveTournament']),
        saveChanges() {
            this.saveTournament().then(() => {
                this.$router.push(`/tournaments/${this.tournament.data.season}/${this.tournament.data.tournamentId}`);
            });
        },
        discardChanges() {
            this.tournament.discardChanges();
        },
        onChange(event) {
            if (event.value === '') {
                this.tournament.data[event.field] = null;
            }
        },
    },
};
</script>
