import _ from 'lodash';

const changes = (source, base) =>
    _.transform(source, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
            result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
        }
    });

export class Model {
    constructor(data = {}) {
        // represents the current state of the model
        this.data = _.cloneDeep(data);

        // represents the original state of the model
        this.original = _.cloneDeep(data);
    }

    isValid() {
        return true;
    }

    isFieldChanged(field) {
        if(field == 'playerOwesMoney'){
            return true;
        } else {
            return this.data[field] !== this.original[field];
        }
    }

    resetField(field) {
        this.data[field] = this.original[field];
    }

    hasChanges() {
        if(this.original.playerOwesMoney != this.data.playerOwesMoney) {
                this.original.playerOwesMoney = this.data.playerOwesMoney;
        }        
        return !_.isEqual(this.original, this.data);
    }

    discardChanges() {
        this.data = _.cloneDeep(this.original);
    }

    saveChanges() {
        this.original = _.cloneDeep(this.data);
    }

    difference() {
        return changes(this.data, this.original);
    }
}

Model.schema = {
    fields: {},
};

export function isValid(schema, model) {
    for (const field of Object.keys(schema.fields)) {
        if (schema.fields[field].required && (model.data[field] === undefined || model.data[field] === null || model.data[field] === '')) {
            return false;
        }

        if (schema.fields[field].specialCharacterAllowed === false && hasSpecialCharacters(model.data[field])) {
            return false;
        }

        if (schema.fields[field].min !== null && model.data[field] && parseInt(model.data[field], 10) < schema.fields[field].min) {
            return false;
        }

        if (schema.fields[field].max !== null && model.data[field] && parseInt(model.data[field], 10) > schema.fields[field].max) {
            return false;
        }

        if (schema.fields[field].minLength !== null && model.data[field] && ('' + model.data[field]).length < schema.fields[field].minLength) {
            return false;
        }

        if (schema.fields[field].maxLength !== null && model.data[field] && ('' + model.data[field]).length > schema.fields[field].maxLength) {
            return false;
        }
    }

    return true;
}

export function hasSpecialCharacters(value) {
    var specialCharRegex = /^(?!^[a-zA-Z0-9 -]+$).*/;
    return specialCharRegex.test(value);
}