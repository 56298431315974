export function retrieveDtos(params) {
    let self = params.self;
    let fRetrieve = params.fRetrieve;
    let options = params.options;
    let dtosName = params.dtosName;
    let callback = params.callback;
    fRetrieve(options)
        .then((data) => {
            self.requestError = null;
            if (data && data.data && data.data.length === 0) {
                self.$notify({
                    title: 'Get ' + dtosName + ' Info',
                    message: 'No ' + dtosName + ' found.',
                    type: 'info',
                    duration: 5000,
                });
            }
            if (callback) {
                callback(data);
            };
        })
        .catch((error) => {
            console.log(error);
            self.requestError = error;
            self.$notify({
                title: 'Get ' + dtosName + ' Error',
                message: error.response.data.Code,
                type: 'error',
                duration: 5000,
            });
        });
}

export async function createConfirmDto(params) {
    let self = params.self;
    let fCreate = params.fCreate;
    let dto = params.dto;
    let dtoName = params.dtoName;
    let callback = params.callback;
    self.$confirm('You are about to add a new  ' + dtoName + '. Please confirm.', 'Add  ' + dtoName, {
        confirmButtonText: 'Add ' + dtoName,
        cancelButtonText: 'Cancel',
        type: 'warning',
    })
        .then(() => {
            fCreate(dto)
                .then((data) => {
                    self.$notify({
                        title: 'Add ' + dtoName,
                        message: dtoName + ' added',
                        type: 'success',
                        duration: 5000,
                    });
                    if (callback) {
                        callback(data);
                    };
                    return Promise.resolve(data);
                })
                .catch((error) => {
                    self.requestError = error;
                    self.$notify({
                        title: 'Add ' + dtoName + ' Error',
                        message: error.response.data.Code,
                        type: 'error',
                        duration: 5000,
                    });
                    return Promise.reject(error);
                });
        })
        .catch(() => {
            self.requestError = null;
            self.$notify({
                title: 'Add ' + dtoName,
                message: dtoName + ' not added',
                type: 'info',
                duration: 5000,
            });
            return Promise.reject();
        });
}

export function updateConfirmDto(params) {
    let self = params.self;
    let fUpdate = params.fUpdate;
    let dto = params.dto;
    let dtoName = params.dtoName;
    let callback = params.callback;
    self.$confirm('You are about to update ' + dtoName + '. Please confirm.', 'Update  ' + dtoName, {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
    })
        .then(() => {
            fUpdate(dto)
                .then(() => {
                    self.$notify({
                        title: 'Update ' + dtoName,
                        message: dtoName + ' updated',
                        type: 'success',
                        duration: 5000,
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch((error) => {
                    self.requestError = error;
                    self.$notify({
                        title: 'Update ' + dtoName + ' Error',
                        message: error.response.data.Code,
                        type: 'error',
                        duration: 5000,
                    });
                });
        })
        .catch(() => {
            self.requestError = null;
            self.$notify({
                title: 'Update ' + dtoName,
                message: dtoName + ' not changed',
                type: 'info',
                duration: 5000,
            });
        });
}

export function deleteConfirmDto(params) {
    let self = params.self;
    let fDelete = params.fDelete;
    let vConfirm = params.vConfirm;
    let dto = params.dto;
    let dtoName = params.dtoName;
    let vConfirmName = params.vConfirmName;
    let callback = params.callback;
    self.$confirm('You are about to delete a current ' + dtoName + '. Please confirm.', 'Delete ' + dtoName, {
        confirmButtonText: 'Delete ' + dtoName,
        cancelButtonText: 'Cancel',
        type: 'warning',
    })
        .then(() => {
            self.$prompt(
                'Warning! This will irrevocably delete the selected ' + dtoName + '. To continue, please enter the ' + vConfirmName,
                'Delete ' + dtoName,
                {
                    confirmButtonText: 'Delete ' + dtoName,
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then((result) => {
                    if (result.action === 'confirm' && result.value === String(vConfirm)) {
                        fDelete(dto)
                            .then((response) => {
                                console.log(response);
                                let msg = dtoName + ' deleted';
                                if (response >= 0)
                                    msg += ' (' + response + ')';
                                self.$notify({
                                    title: 'Delete ' + dtoName,
                                    message: msg,
                                    type: 'success',
                                    duration: 5000,
                                });
                                if (callback) {
                                    callback();
                                }
                            })
                            .catch((error) => {
                                self.requestError = error;
                                self.$notify({
                                    title: 'Delete ' + dtoName,
                                    message: error.response.data.Code,
                                    type: 'error',
                                    duration: 5000,
                                });
                            });
                    } else {
                        self.requestError = null;
                        self.$notify({
                            title: 'Delete ' + dtoName,
                            message: dtoName + ' not deleted',
                            type: 'info',
                            duration: 5000,
                        });
                    }
                })
                .catch(() => { });
        })
        .catch(() => { });
}
