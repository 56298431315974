<script>
import PlayerWildcardsStatsCard from '@/components/player-wildcards/PlayerWildcardsStatsCard.vue';

export default {
    name: 'PlayerWildcardsStatsDetails',
    components: { PlayerWildcardsStatsCard },
    computed: {
        playerData() {
            return this.$store.state.tournamentWildcards.tournamentWildcardsByPlayer;
        }
    }
};
</script>

<template>
    <div>
        <h4>Wildcards Details</h4>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card>
                    <h2>Singles Wildcards</h2>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <PlayerWildcardsStatsCard title="Tour Wildcards" :eligible="playerData.singlesWildcardsElegible" :used="playerData.singlesWildcardsUsed"  />
                        </el-col>
                        <el-col :span="12">
                            <PlayerWildcardsStatsCard title="125K Wildcards" :eligible="playerData.singlesWTA125WildcardsElegible" :used="playerData.singlesWTA125WildcardsUsed" />
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card>
                    <h2>Doubles Wildcards</h2>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <PlayerWildcardsStatsCard title="Tour Wildcards" :eligible="playerData.doublesWildcardsElegible" :used="playerData.doublesWildcardsUsed" />
                        </el-col>
                        <el-col :span="12">
                            <PlayerWildcardsStatsCard title="125K Wildcards" :eligible="playerData.doublesWTA125WildcardsElegible" :used="playerData.doublesWTA125WildcardsUsed" />
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>

</style>