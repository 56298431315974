<template>
    <div>       
        <div class="flex-row mt-0 mb-32">
            <h2>Current Season Entry/Withdrawal</h2>
            <span class="spacer"></span>                       
        </div>      

        <div class="flex-row mt-0 mb-32">
            <h2>Administration</h2>
            <span class="spacer"></span>
            <el-button @click="filterAll" size="mini" plain type="primary" class="mr-8">ALL</el-button>  |  
            <el-button @click="filterEntry" size="mini" plain type="primary" class="mr-8">ENTRIES</el-button>  |  
            <el-button @click="filterWithdrawal" size="mini" plain type="primary" class="mr-8">WITHDRAWALS</el-button>              
        </div>
        <hr class="divider" />
        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="All History" name="all"></el-tab-pane>
            <el-tab-pane label="Singles History" name="S"></el-tab-pane>
            <el-tab-pane label="Doubles History" name="D"></el-tab-pane>
        </el-tabs>
        <hr class="divider" />
        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-loading="getLoadingPlayerEntryAndWithdrawalHistoryData" class="mt-0 mb-32">
            <DataTable
                :fields="computedFields"
                :items="getPlayerEntryAndWithdrawalHistoryData.data"
                :schema="schema"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                :autoSort="false"
                @header-clicked="setSort"
            />

            <Paginator
                v-if="getPlayerEntryAndWithdrawalHistoryData.data"
                :data="getPlayerEntryAndWithdrawalHistoryData"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
        <div class="flex-row mt-0 mb-32">
            <h2>More Information</h2>
            <span class="spacer"></span>            
        </div>
        <hr class="divider" />
        <div class="flex-row mt-0 mb-32">            
            <el-button @click="redirectPlayerEntryHistoryReport" size="max" plain type="primary" class="mr-8">Player Entry History Report</el-button>  |  
            <el-button @click="redirectWithdrawalReasonReport" size="max" plain type="primary" class="mr-8">Withdrawal Reason Report</el-button>            
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerEntryAndWithdrawalHistory } from '../models/player-entry-and-withdrawal-history';
import { retrieveDtos } from '@/utils/crud-confirm';

export default {
    components: {
    },
    props: ['playerId'],
    data() {
        return {
            schema: PlayerEntryAndWithdrawalHistory.schema,                      
            requestError: null,
            season: null,            
            //playerId: null,
            options: {
                queryString: null,
                sortBy: '',
                sortDir: 'desc',
                page: 0,
                pageSize: 20,
                isEntryHistory: false,
                isWithdrawal : false,
                eventTypeCode: null,
            },
            activeTab: 'all'
        };
    },
    mounted() {                          
        this.search();
    },
    computed: {
        ...mapGetters('playerEntryAndWithdrawalHistory', ['getLoadingPlayerEntryAndWithdrawalHistoryData', 'getLoadingError', 'getPlayerEntryAndWithdrawalHistoryData']),
        computedFields() {
            if (this.options.eventTypeCode !== 'S') {
                return ['tournamentName', 'partnerName', 'entryStatus', 'entryDate'];
            }
            return ['tournamentName', 'entryStatus', 'entryDate'];
        },
    },
    methods: {
        ...mapActions('playerEntryAndWithdrawalHistory', ['findPlayerEntryAndWithdrawalHistory']),                  
        filterAll(){
            this.options.isEntryHistory = false;
            this.options.isWithdrawal = false;
            this.search();
        },
        filterEntry(){
            this.options.isEntryHistory = true;
            this.options.isWithdrawal = false;
            this.search();
        },
        filterWithdrawal(){
            this.options.isEntryHistory = false;
            this.options.isWithdrawal = true;
            this.search();
        },
        redirectPlayerEntryHistoryReport() {            
            const playerNbr = this.playerId;
            const tournamentYear = new Date().getFullYear();            
            this.$router.push({ name: 'player-entry-history', params: { playerNbr, tournamentYear }});
        },
        redirectWithdrawalReasonReport() {            
            const playerNbr = this.playerId;
            const tournamentYear = new Date().getFullYear();            
            this.$router.push({ name: 'withdrawal-reason', params: { playerNbr, tournamentYear }});
        },
        clearError() {
            this.requestError = null;
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },       
        search() {                                                            
            const body = {
                options: this.options,               
                playerId: this.playerId                
            };
            let params = {
                self: this,
                fRetrieve: this.findPlayerEntryAndWithdrawalHistory,
                options: body,
                dtosName: 'Entries And Withdrawals History',
            };
            retrieveDtos(params);
        },
        handleTabClick(tab) {
            this.options.eventTypeCode = tab.name;
            this.search();
        },
    },
};
</script>

<style scoped>
.divider {
  border: 1px solid #ccc;
  margin: 10px 0;
}
</style>
