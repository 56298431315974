<script>
import { Player } from '@/models/player';
import PlayerWildcardsStats from '@/components/player-wildcards/PlayerWildcardsStats.vue';
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
    name: 'PlayerCompetition',
    components: {
        PlayerWildcardsStats
    },
    data() {
        return {
            playerId: this.$route.params.id,
            schema : Player.schema,
            layoutCompetition: Player.Competition,
            yearToShowWildcardsStats: new Date().getFullYear(),
        }
    },
    computed: {
        ...mapGetters('playerDetails', ['player']),
    },
    methods: {
        editCurrentRank() {
            this.$router.push({
                name: 'current-ranking',
                query: {
                    playerId: this.playerId
                }
            });
        },
        editSpecialRank() {
            this.$router.push({
                name: 'special-ranking',
                query: {
                    playerId: this.playerId
                }
            });
        },
    }
}
</script>

<template>
    <div>
        <FormGroup :model="player" :schema="schema" :layout="layoutCompetition" />
        <el-button @click="editCurrentRank" type="primary">Edit Current Rank</el-button>
        <el-button @click="editSpecialRank" type="primary">Edit Special Rank</el-button>
        <el-divider></el-divider>
        <PlayerWildcardsStats :playerId="playerId" :year="yearToShowWildcardsStats" />
    </div>
</template>

<style scoped></style>