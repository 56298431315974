<template>
    <div>
        <el-dialog
            v-if="!isReadOnlyAccess"
            :visible.sync="editDialogVisible"
            :close-on-click-modal="false"
            :title="mode === 'edit' ? 'Edit Sys Code' : 'Add Sys Code'"
            width="65%"
        >
            <FormGroup :model="selectedSysCode" :schema="sysCodeSchema" :layout="layout" @change="onChange" />
            <div class="dialog-footer">
                <el-button type="primary" @click="saveChanges" :disabled="!selectedSysCode || !selectedSysCode.isValid() || !selectedSysCode.hasChanges()"
                    >Save changes</el-button
                >
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>
                <el-button v-if="mode === 'edit' && !isReadOnlyAccess" plain type="danger" @click="deleteItem">Delete Sys Code</el-button>
            </div>
        </el-dialog>

        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-set-up mr-16"></i>System Table</h2>
            <span class="spacer"></span>
            <el-input
                placeholder="Filter by table name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
                size="mini"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
            <el-button v-if="!isReadOnlyAccess" @click="addSysCode" size="mini" plain type="primary"> Add Sys Code </el-button>
            <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation screenName="System Table" :informationArray="this.informationArray()" />

        <div v-loading="loadingSysCodes">
            <DataTable
                :items="sysCodes.data"
                :schema="sysCodeSchema"
                :fields="['tableName', 'code', 'status', 'ref', 'description']"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                @item-clicked="itemClicked"
                @header-clicked="setSort"
                :autoSort="false"
            />

            <Paginator
                v-if="sysCodes.data"
                :data="sysCodes"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SysCode } from '../models/sys-code';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto } from '../utils/crud-confirm';
import { isReadOnlyAccess } from '../services/authGroups';

export default {
    data() {
        return {
            sysCodeSchema: SysCode.schema,
            layout: SysCode.layout,
            editDialogVisible: false,
            selectedSysCode: null,
            mode: null,
            requestError: null,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.refresh();
    },
    computed: mapGetters('sysCodes', ['sysCodes', 'loadingSysCodes', 'savingSysCode', 'deletingSysCode', 'options']),
    methods: {
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `sys_codes_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        ...mapActions('sysCodes', ['findSysCodes', 'saveSysCode', 'deleteSysCode', 'exportCsv']),
        refresh() {
            this.search();
        },
        itemClicked(item) {
            this.selectedSysCode = new SysCode(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        saveChanges() {
            this.saveSysCode(this.selectedSysCode)
                .then(() => {
                    this.refresh();
                    this.requestError = null;
                    this.editDialogVisible = false;
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        onChange() {},
        search() {
            let params = {
                self: this,
                fRetrieve: this.findSysCodes,
                options: this.options,
                dtosName: 'Sys Coddes',
            };
            retrieveDtos(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            console.log(event);
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.deleteSysCode,
                vConfirm: this.selectedSysCode.data.tableName,
                dto: this.selectedSysCode,
                dtoName: 'Sys Code',
                vConfirmName: 'Table Name',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        addSysCode() {
            this.selectedSysCode = new SysCode({
                season: this.tournamentYear,
                tournamentId: this.tournamentId,
            });
            this.mode = 'add';
            this.editDialogVisible = true;
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This area provides an overview about system codes.',
                'System codes are fetched from table sys_codes',
                'Adding new system code is possible by clicking button "Add Sys Code."',
                '<b>Attention!</b> Adding new system code if entry is already present will not throw an error but will override  data.',
            ];
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 384px;
}
</style>
