<template>
    <div>
        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>{{ title() }}</h2>

            <span class="spacer"></span>

            <SelectPlayer :value="selectedPlayerFullName" class="mr-8" @palyer-selected="playerSelected" />
            <el-button plain size="mini" v-if="!isReadOnlyAccess" type="primary" @click="reset">Reset</el-button>
            <el-button plain size="mini" v-if="!isReadOnlyAccess" type="primary" @click="addItem">Add Ranking</el-button>
            <el-button class="ml-8" plain size="mini" @click="exportCsvClick">Export CSV</el-button>
        </div>

        <DetailedInformation :informationArray="this.informationArray()" screenName="Current Rankings" />

        <el-tabs v-if="isBefore2024RuleChangesDate" v-model="activeView">
            <el-tab-pane label="Singles Rankings" name="singles">
                <SinglesRankings ref="singles" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>

            <el-tab-pane label="Doubles Rankings" name="doubles">
                <DoublesRankings ref="doubles" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>

            <el-tab-pane label="Championship Singles" name="champSingles">
                <ChampSinglesRankings ref="champSingles" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>

            <el-tab-pane label="Team Rankings" name="teamPoints">
                <TeamPoints ref="teamPoints" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>
        </el-tabs>

        <el-tabs v-else v-model="activeView">
            <el-tab-pane label="Singles Rankings" name="singles">
                <SinglesRankings ref="singles" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>

            <el-tab-pane label="Doubles Rankings" name="doubles">
                <DoublesRankings ref="doubles" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>

            <el-tab-pane label="Race Singles" name="raceSinglesWtaAndGs">
                <RaceSinglesWtaAndGsRankings ref="raceSinglesWtaAndGs" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>

            <el-tab-pane label="Team Rankings" name="teamPoints">
                <TeamPoints ref="teamPoints" :playerId="playerId" @refresh="refresh" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as moment from 'moment';
import RaceSinglesWtaAndGsRankings from './RaceSinglesWtaAndGsRankings.vue';
import { CurrentRanking } from '../../models/current-ranking';
import { isReadOnlyAccess } from '../../services/authGroups';

export default {
    components: {
        RaceSinglesWtaAndGsRankings,
    },
    data() {
        return {
            activeView: 'singles',
            playerId: null,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.retrieveWtaSystem().then(() => {
            let rankDateString = moment(this.wtaSystem.data.currentRankDate).format('YYYY-MM-DD');
            CurrentRanking.adaptDisplayNames(rankDateString);
        });
    },
    computed: {
        ...mapGetters('wtaSystem', ['wtaSystem']),
        ...mapGetters('currentOverview', ['selectedPlayerFullName']),
        isBefore2024RuleChangesDate() {
            if (!this.wtaSystem) {
                return false;
            }
            let rankDateString = moment(this.wtaSystem.data.currentRankDate).format('YYYY-MM-DD');
            console.log(rankDateString);
            return rankDateString < '2024-01-01';
        },
    },
    methods: {
        ...mapActions('wtaSystem', ['retrieveWtaSystem']),
        ...mapActions('currentOverview', ['setSelectedPlayerFullName']),
        playerSelected(player) {
            this.playerId = player.id;
            this.setSelectedPlayerFullName(player.fullName);
        },
        reset() {
            this.playerId = null;
            this.setSelectedPlayerFullName(null);
            this.$refs.singles.reset();
            this.$refs.doubles.reset();
            this.$refs.champSingles.reset();
            this.$refs.teamPoints.reset();
            this.$refs.raceSinglesWtaAndGs.reset();
        },
        refresh() {
            this.$refs.singles.refresh();
            this.$refs.doubles.refresh();
            this.$refs.champSingles.refresh();
            this.$refs.teamPoints.refresh();
            this.$refs.raceSinglesWtaAndGs.refresh();
        },
        addItem() {
            this.$refs[this.activeView].addItem();
        },
        exportCsvClick() {
            this.$refs[this.activeView].exportCsvClick();
        },
        title() {
            var title = 'Current Rankings';
            if (this.wtaSystem) {
                title += ' for ' + moment(this.wtaSystem.data.currentRankDate).format('YYYY-MM-DD');
            }
            return title;
        },
        informationArray() {
            return [
                'This screen is used to display Current Rankings for players and teams.',
                'Current Player Rankings (tabs <b>Singles Rankings</b>, <b>Doubles Rankings</b>, <b>Championship Singles</b>) are fetched from table player_rank_current.',
                'Current Team Rankings (tabs <b>Team Rankings</b>) are fetched from table team_points.',
                'Current Player Rankings with Rank of 9999 are not displayed. All Current Team Rankings are displayed.',
                'Search for player dropdown is possible by player_nbr <b>or</b> name_first <b>or</b> name_last. Combined search is not possible.',
                '<b>Adding</b> a new Player Ranking/ Team Ranking is possible <b>by clicking respective button"Add Player Ranking"/ "Add Team Ranking"</b>. Adding a new Ranking if data is already present will result in an error.',
                '<b>Editing</b> a present Player Ranking/ Team Ranking is possible <b>by clicking on an entry in the table below</b>.',
                '<b>Deleting</b> a present Player Ranking/ Team Ranking is possible <b>in the Edit Menu by clicking button "Delete Player Ranking"/  "Add Team Ranking"</b>. Delete has to be confirmed.',
                'By default Rankings are generated by running Ranking Procedure.',
                'CSV Export of each table is possible by clicking respective button.',
            ];
        },
    },
};
</script>
