import axios from 'axios';

export default {
    namespaced: true,

    state: {
        tournamentWildcardsByPlayer: {},
        loadingTournamentWildcardsByPlayer: false,
        errorLoadingTournamentWildcardsByPlayer: null,
    },

    mutations: {
        SET_TOURNAMENT_WILDCARDS_BY_PLAYER(state, payload) {
            state.tournamentWildcardsByPlayer = payload;
        },

        SET_LOADING_TOURNAMENT_WILDCARDS_BY_PLAYER(state, payload) {
            state.loadingTournamentWildcardsByPlayer = payload;
        },

        SET_ERROR_LOADING_TOURNAMENT_WILDCARDS_BY_PLAYER(state, payload) {
            state.errorLoadingTournamentWildcardsByPlayer = payload;
        },
    },

    actions: {
        async loadTournamentWildcardsByPlayer({ commit }, { year, playerId }) {
            commit('SET_LOADING_TOURNAMENT_WILDCARDS_BY_PLAYER', true);
            commit('SET_ERROR_LOADING_TOURNAMENT_WILDCARDS_BY_PLAYER', null);
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/tournament-wildcards/${year}/${playerId}`);
                commit('SET_TOURNAMENT_WILDCARDS_BY_PLAYER', response.data);
            } catch (error) {
                commit('SET_ERROR_LOADING_TOURNAMENT_WILDCARDS_BY_PLAYER', error);
            } finally {
                commit('SET_LOADING_TOURNAMENT_WILDCARDS_BY_PLAYER', false);
            }
        },
    },
};