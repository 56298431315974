<template>
    <div v-if="model.hasChanges() && !isBaselineReader" :class="{ 'fixed-buttons': true, 'p-16': true, shadow: true, panel: true, error: !isValid }">
        <div class="mb-16">
            <small v-if="!isValid">Some required fields are empty or contain invalid data. Please check your input!</small>
        </div>
        <el-button @click="$emit('save')" :disabled="!isValid" type="primary" class="shadow"> Save changes </el-button>
        <el-button @click="$emit('discard')"> Discard changes </el-button>
        <div class="additional-message" v-if="showAdditionalMessage">Please pay attention to messages on top of the page!</div>
    </div>
</template>

<script>
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['model', 'showAdditionalMessage'],
    data() {
        return {
            isBaselineReader: isBaselineReader(),            
        };
    },
    computed: {
        isValid() {
            return this.model.isValid();
        },
    },
};
</script>

<style scoped lang="scss">
.fixed-buttons {
    position: fixed;
    right: 24px;
    bottom: 24px;
    margin-top: 64px;
}
.panel {
    background-color: white;
    border: 2px solid green;
    border-radius: 2px;

    &.error {
        border: 2px solid red;
    }
}

.additional-message {
    padding-top: 5px;
    color: #8c0615;
}
</style>
