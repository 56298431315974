<template>
    <div>
        <div class="flex-row mt-16 mb-32">
            <h2>PZ Access for Coaches</h2>
            <span class="spacer"></span>

            <el-input
                v-model="options.queryString"
                class="input-search mr-16"
                placeholder="Filter by Id or Name"
                @change="resetPage"
                @keydown.esc.native="reset"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>

            <el-button plain size="mini" type="primary" v-if="!isReadOnlyAccess" @click="addPzAccess">Add Coach</el-button>
        </div>

        <DialogEditItem
            :layout="layout"
            :schema="schema"
            :selected="selectedPzAccess"
            :visibleSync="editDialogVisible"
            dtoName="Pz Access"
            @change="onChange"
            @close="onClose"
            @delete="deletePzAccessClick"
            @next="gotoNextPzAccess"
            @prev="gotoPrevPzAccess"
            @save="saveChanges"
            :isServiceDeskAccess="exceptionEditFooterShow"            
        />

        <DialogAddItem
            :layout="layoutAdd"
            :schema="schemaAdd"
            :selected="selectedPzAccess"
            :visibleSync="addDialogVisible"
            dtoName="Pz Access"
            @change="onChange"
            @close="addDialogVisible = false"
            @save="saveChanges"
        />

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-loading="loadingPzCoachAccesses" class="mt-8 w-80">
            <DataTable
                :autoSort="false"
                :fields="['plyrNum', 'username', 'role', 'firstName', 'lastName', 'created', 'email', 'owesMoney']"
                :items="pzCoachAccesses.data"
                :schema="schema"
                :sortDir="options.sortDir"
                :sortField="options.sortBy"
                @header-clicked="setSort"
                @item-clicked="itemClicked"
            />
            <Paginator
                v-if="pzCoachAccesses.data"
                :data="pzCoachAccesses"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { PzAccess } from '@/models/pz-access';
import { mapActions, mapGetters } from 'vuex';
import { createConfirmDto, deleteConfirmDto, retrieveDtos, updateConfirmDto } from '@/utils/crud-confirm';
import { isReadOnlyAccess,isBaselineServiceDesk } from '../../services/authGroups';

export default {
    data() {
        return {
            schema: PzAccess.schema,
            layout: PzAccess.layout,
            schemaAdd: PzAccess.schemaAdd,
            layoutAdd: PzAccess.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            selectedPzAccess: null,
            requestError: null,
            pzAccessPointer: null,
            mode: null,
            options: {
                queryString: null,
                sortBy: 'plyrNum',
                sortDir: 'descending',
                page: 0,
                pageSize: 20,
            },
            isReadOnlyAccess: isReadOnlyAccess(),  
            isBaselineServiceDesk: isBaselineServiceDesk(),          
        };
    },
    mounted() {
        this.search();
    },
    computed: {
        ...mapGetters('pzAccessCoaches', ['pzCoachAccesses', 'loadingPzCoachAccesses', 'loadingErrorPzCoachAccesses']),
       exceptionEditFooterShow: function() {            
                if(this.isBaselineServiceDesk) {
                     return true;
                } else {            
                     return false;
                }
        }
    },
    methods: {
        ...mapActions('pzAccessCoaches', ['findPzAccessesForCoaches', 'createPzAccessForCoach', 'updatePzCoachAccess', 'deletePzCoachAccess']),
        search() {
            let params = {
                self: this,
                fRetrieve: this.findPzAccessesForCoaches,
                options: this.options,
                dtosName: 'Pz Accesses',
            };
            retrieveDtos(params);
        },
        itemClicked(item, index) {
            this.selectedPzAccess = new PzAccess(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.pzAccessPointer = index;
        },
        addPzAccess() {
            this.selectedPzAccess = new PzAccess();
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updatePzCoachAccess,
                    dto: this.selectedPzAccess,
                    dtoName: 'Pz Access',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createPzAccessForCoach,
                    dto: this.selectedPzAccess,
                    dtoName: 'Pz Access',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        deletePzAccessClick() {
            let params = {
                self: this,
                fDelete: this.deletePzCoachAccess,
                vConfirm: this.selectedPzAccess.data.plyrNum,
                dto: this.selectedPzAccess,
                dtoName: 'Pz Access',
                vConfirmName: 'Id',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
            this.search();
        },
        onClose() {
            this.editDialogVisible = false;
        },
        onChange() {},
        clearError() {
            this.requestError = null;
        },
        gotoPrevPzAccess() {
            if (this.pzAccessPointer === 0) {
                return;
            }
            this.pzAccessPointer -= 1;
            this.selectedPzAccess = new PzAccess(this.pzCoachAccesses.data[this.pzAccessPointer]);
        },
        gotoNextPzAccess() {
            if (this.pzAccessPointer === this.pzCoachAccesses.length - 1) {
                return;
            }
            this.pzAccessPointer += 1;
            this.selectedPzAccess = new PzAccess(this.pzCoachAccesses.data[this.pzAccessPointer]);
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
    },
};
</script>

<style scoped></style>
