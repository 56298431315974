import { render, staticRenderFns } from "./PlayerSupportCoach.vue?vue&type=template&id=09d6a04b&scoped=true"
import script from "./PlayerSupportCoach.vue?vue&type=script&lang=js"
export * from "./PlayerSupportCoach.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09d6a04b",
  null
  
)

export default component.exports