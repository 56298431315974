<template>
    <div v-loading="savingSinglesEntry || savingDoublesEntry">
        <el-dialog :visible.sync="editDialogVisible" :close-on-click-modal="false" title="Withdraw Entry" width="65%">
            <el-alert type="info" :closable="false">
                This dialog allows you to withdraw a player. The following shows the logic that is applied to each players entry depending on how is the state
                of the acceptance list. For the latter case the player will be withdrawn completely. This will lead to the player still being listed on the
                acceptance list but with a strike through.
                <ul>
                    <li>
                        Before acceptance list has been processed
                        <ul>
                            <li>Entered (EN) -> Withdrawn before entry deadline (WD)</li>
                        </ul>
                    </li>
                    <li>
                        After acceptance list has been processed
                        <ul>
                            <li>Entered (EN) -> Withdrawn after entry deadline (WE)</li>
                            <li>Accepted into Main Singles (MD) -> Withdrawn after entry deadline (WM)</li>
                            <li>Accepted into Qualification (QL) -> Withdrawn after entry deadline (WQ)</li>
                            <li>Accepted into Main Doubles (MD) -> Withdrawn after entry deadline (WE)</li>
                        </ul>
                    </li>
                </ul>
            </el-alert>
            <div v-if="selectedPlayerEntry" class="player-info">
                You have selected player {{ selectedPlayerEntry.data.playerFullName }}. Withdrawing this player will have the following effect:
                <span v-if="selectedPlayerEntry.data.entryStatus === 'EN'">
                    This player has entered the tournament but has not been accepted. Withdrawing will set this players entry to state WE.
                </span>
                <span v-if="selectedPlayerEntry.data.entryStatus === 'MD'">
                    This player has been accepted into Main. Withdrawing will set this players entry to state WM. The first player from alternates will become
                    accepted into draw.
                </span>
                <span v-if="selectedPlayerEntry.data.entryStatus === 'QL'">
                    This player has been accepted into Qualifying. Withdrawing will set this players entry to state WQ. The first player from alternates will
                    become accepted into qualification draw.
                </span>
            </div>
            <div
                class="alternate-list-player-selection"
                v-if="selectedPlayerEntry && (selectedPlayerEntry.data.entryStatus === 'MD' || selectedPlayerEntry.data.entryStatus === 'QL')"
            >
                If you do not want to automatically advance the first player from the alternates list, then you can select another player from the alternates
                list.
                <div style="padding-top: 5px">
                    <el-select v-model="playerFromAlternates" filterable placeholder="Select a player">
                        <el-option :key="-1" :label="''" :value="null"></el-option>
                        <el-option v-for="(item, i) in singlesEntriesOnAlternateList" :key="i" :label="item.playerFullName" :value="item.playerId"> </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="!isReadOnlyAccess" class="dialog-footer">
                <el-button plain type="danger" @click="withdrawPlayer"
                    >Withdraw Player from {{ sDFlag === 's' ? 'Singles' : 'Doubles' }} {{ qMFlag === 'm' ? 'Main' : 'Qualification' }}</el-button
                >
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>
            </div>
        </el-dialog>

        <div v-loading="loadingSinglesEntries || loadingDoublesEntries" class="flex-row mt-0 mb-32">
            <!-- <span class="radio-title">S/D:</span> -->
            <el-radio-group v-model="sDFlag" size="mini" class="mr-32">
                <el-radio-button label="s">Singles</el-radio-button>
                <el-radio-button label="d">Doubles</el-radio-button>
            </el-radio-group>

            <!-- <span class="radio-title">Q/M:</span> -->
            <el-radio-group v-model="qMFlag" size="mini" :disabled="sDFlag === 'd'" class="mr-32">
                <el-radio-button label="q">Quali</el-radio-button>
                <el-radio-button label="m">Main</el-radio-button>
            </el-radio-group>

            <!-- <span class="radio-title">Status:</span> -->
            <el-radio-group v-model="status" size="mini" class="mr-32">
                <el-radio-button label="en">Entered</el-radio-button>
                <el-radio-button label="accepted">Accepted</el-radio-button>
                <el-radio-button label="other">Other</el-radio-button>
            </el-radio-group>

            <span class="radio-title">Age</span>
            <el-radio-group v-model="age" size="mini">
                <el-radio-button label="all">All</el-radio-button>
                <el-radio-button label="14">14</el-radio-button>
                <el-radio-button label="15">15</el-radio-button>
                <el-radio-button label="16">16</el-radio-button>
                <el-radio-button label="17">17</el-radio-button>
                <el-radio-button label="18+">18+</el-radio-button>
                <el-radio-button label="35+">35+</el-radio-button>
            </el-radio-group>

            <span class="spacer"></span>

            <el-input
                size="mini"
                placeholder="Filter by Id or Name"
                v-model="queryString"
                class="input-search ml-32"
                @keydown.esc.native="queryString = ''"
                :clearable="true"
            >
            </el-input>

            <el-button @click="exportCsv" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <el-alert type="info" :closable="false" :show-icon="true">
            This area shows you all entries for this tournament. You can filter by "Accepted" which means that it will show you an acceptance list once the
            procedure has been run. Make sure to sort by entry rank in this case. The default filter is set to "Entered" which is the same as the preliminary
            list. In addition to these you can also sort by age or draw. If you want to withdraw a player after the acceptance list has run then click on a
            player and select the right option.
        </el-alert>

        <div :hidden="!showSingles">
            <DataTable
                :items="singlesEntriesData"
                :fields="[
                    'playerFullName',
                    'entryRank',
                    'currentSinglesRank',
                    'playerAgeAtTournamentStart',
                    'playerNationality',
                    'entryPreference',
                    'within',
                    'entryStatus',
                    'entryDate',
                ]"
                :schema="singlesEntriesSchema"
                :autoSort="true"
                @item-clicked="itemClicked"
            />
        </div>

        <div :hidden="showSingles">
            <DataTable
                :items="doublesEntriesData"
                :fields="[
                    'combinedRank',
                    'playerFullName',
                    'playerCurrentDoublesRank',
                    'playerEntrySinglesRank',
                    'playerEntrySpecialRank',
                    'playerAgeAtTournamentStart',
                    'playerNationality',
                    'partnerFullName',
                    'partnerCurrentDoublesRank',
                    'partnerEntrySinglesRank',
                    'partnerEntrySpecialRank',
                    'partnerAgeAtTournamentStart',
                    'partnerNationality',
                    'entryPreference',
                    'within',
                    'entryStatus',
                    'entryDate',
                ]"
                :schema="doublesEntriesSchema"
                :autoSort="true"
                @item-clicked="itemClicked"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SinglesEntry } from '../models/singles-entry';
import { DoublesEntry } from '../models/doubles-entry';
import FileSaver from 'file-saver';
import moment from 'moment';
import { csvExporter } from '../utils/csv-exporter';
import { SysCode } from '../models/sys-code';
import { isReadOnlyAccess } from '../services/authGroups';

export default {
    data() {
        return {
            singlesEntriesSchema: SinglesEntry.schema,
            doublesEntriesSchema: DoublesEntry.schema,
            qMFlag: 'm',
            sDFlag: 's',
            age: 'all',
            queryString: '',
            status: 'en',
            editDialogVisible: false,
            selectedPlayerEntry: null,
            playerFromAlternates: null,
            isReadOnlyAccess: isReadOnlyAccess(),           
        };
    },
    methods: {
        ...mapActions('singlesEntries', ['withdrawSinglesEntry']),
        ...mapActions('doublesEntries', ['withdrawDoublesEntry']),
        exportCsv() {
            const data = csvExporter(
                this.showSingles ? this.singlesEntriesSchema : this.doublesEntriesSchema,
                this.showSingles ? this.singlesEntriesData : this.doublesEntriesData,
            );
            const file = new File([data], `preliminary_list_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
            FileSaver.saveAs(file);
        },
        filterStatus(entry) {
            switch (this.status) {
                case 'en':
                    return entry.entryStatus === 'EN';
                case 'accepted':
                    return entry.entryStatus === 'MD' || entry.entryStatus === 'QL';
                default:
                    return entry.entryStatus !== 'EN' && entry.entryStatus !== 'MD' && entry.entryStatus !== 'QL';
            }
        },
        filterQMFlag(entry) {
            if (this.sDFlag === 'd') {
                return true;
            }
            switch (this.qMFlag) {
                case 'm':
                    return entry.entryMode === 'M';
                default:
                    return entry.entryMode === 'Q';
            }
        },
        filterNameOrId(entry) {
            return (
                (entry.playerId && ('' + entry.playerId).toLowerCase().includes(this.queryString.toLowerCase())) ||
                (entry.playerFullName && entry.playerFullName.toLowerCase().includes(this.queryString.toLowerCase())) ||
                (entry.partnerId && ('' + entry.partnerId).toLowerCase().includes(this.queryString.toLowerCase())) ||
                (entry.partnerFullName && entry.partnerFullName.toLowerCase().includes(this.queryString.toLowerCase()))
            );
        },
        filterAge(entry) {
            switch (this.age) {
                case 'all':
                    return true;
                case '14':
                    return entry.playerAgeAtTournamentStart === 14 || entry.partnerAgeAtTournamentStart === 14;
                case '15':
                    return entry.playerAgeAtTournamentStart === 15 || entry.partnerAgeAtTournamentStart === 15;
                case '16':
                    return entry.playerAgeAtTournamentStart === 16 || entry.partnerAgeAtTournamentStart === 16;
                case '17':
                    return entry.playerAgeAtTournamentStart === 17 || entry.partnerAgeAtTournamentStart === 17;
                case '18+':
                    return (
                        (entry.playerAgeAtTournamentStart >= 18 && entry.playerAgeAtTournamentStart < 35) ||
                        (entry.partnerAgeAtTournamentStart >= 18 && entry.partnerAgeAtTournamentStart < 35)
                    );
                case '35+':
                    return entry.playerAgeAtTournamentStart >= 35 || entry.partnerAgeAtTournamentStart >= 35;
            }
            return false;
        },
        itemClicked(item) {
            if (this.sDFlag === 's' && (item.entryMode === 'M' || item.entryMode === 'Q')) {
                this.selectedPlayerEntry = new SinglesEntry(item);
                this.editDialogVisible = true;
            }
            if (this.sDFlag === 'd') {
                this.selectedPlayerEntry = new DoublesEntry(item);
                this.editDialogVisible = true;
            }
        },
        withdrawPlayer() {
            if (this.sDFlag === 's') {
                this.selectedPlayerEntry.playerFromAlternates = this.playerFromAlternates;
                this.withdrawSinglesEntry(this.selectedPlayerEntry).then(() => {
                    this.editDialogVisible = false;
                    this.playerFromAlternates = null;
                    this.selectedPlayerEntry.playerFromAlternates = null;
                });
            } else {
                this.withdrawDoublesEntry(this.selectedPlayerEntry).then(() => {
                    this.editDialogVisible = false;
                });
            }
        },
    },
    computed: {
        ...mapGetters('singlesEntries', ['singlesEntries', 'loadingSinglesEntries', 'savingSinglesEntry']),
        ...mapGetters('doublesEntries', ['doublesEntries', 'loadingDoublesEntries', 'savingDoublesEntry']),
        singlesEntriesData() {
            const result = {};

            if (!this.singlesEntries) {
                return [];
            }

            for (const entry of this.singlesEntries.filter(
                (e) => this.filterAge(e) && this.filterNameOrId(e) && this.filterStatus(e) && this.filterQMFlag(e),
            )) {
                if (!result[entry.playerId]) {
                    result[entry.playerId] = entry;
                } else {
                    if (new Date(entry.entryDate) > new Date(result[entry.playerId].entryDate)) {
                        result[entry.playerId] = entry;
                    }
                }
            }

            return Object.values(result);
        },
        doublesEntriesData() {
            const result = {};

            if (!this.doublesEntries) {
                return [];
            }

            for (const entry of this.doublesEntries.filter(
                (e) => this.filterAge(e) && this.filterNameOrId(e) && this.filterStatus(e) && this.filterQMFlag(e),
            )) {
                if (!result[entry.playerId]) {
                    result[entry.playerId] = entry;
                } else {
                    if (new Date(entry.entryDate) > new Date(result[entry.playerId].entryDate)) {
                        result[entry.playerId] = entry;
                    }
                }
            }

            return Object.values(result);
        },
        singlesEntriesOnAlternateList() {
            return this.singlesEntries.filter((e) => e.entryStatus === 'EN');
        },
        showSingles() {
            return this.sDFlag === 's';
        },
    },
};
</script>

<style scoped>
.radio-title {
    color: #888;
    font-size: 12px;
    margin-right: 8px;
    margin-left: 16px;
}

.input-search {
    max-width: 196px;
    margin-left: 32px;
}

.player-info {
    margin-top: 15px;
}

.alternate-list-player-selection {
    margin-top: 10px;
}
</style>
