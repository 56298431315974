<template>
    <el-container class="main-content">
        <TopNav @toggle-burger="sideNav = !sideNav" />
        <el-container v-loading="!ready">
            <SideNav v-if="sideNav" class="shadow" />
            <el-container>
                <el-main>
                    <router-view v-if="ready" />
                </el-main>
                <!-- <Footer /> -->
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
import TopNav from '../widgets/TopNav.vue';
import SideNav from '../widgets/SideNav.vue';
import Footer from '../widgets/Footer.vue';

export default {
    components: {
        TopNav,
        SideNav,
        // Footer,
    },
    data() {
        return {
            mode: process.env.VUE_APP_APPLICATION_MODE,
            sideNav: true,
            ready: false,
        };
    },
    mounted() {
        this.$store
            .dispatch('sysCodes/applySysCodes')
            .then(() => {
                this.ready = true;
            })
            .catch((error) => {
                console.error(error);
                this.ready = true;
                this.$notify({
                    title: 'System table codes could not be loaded!',
                    message: 'Default values will be used for all dropdown fields',
                    type: 'error',
                    duration: 10000,
                });
            });
    },
};
</script>

<style scoped lang="scss">
.main-content {
    padding-top: 70px;
}
</style>
