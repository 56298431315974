<template>
    <div>
        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-if="bioDetFound">
            <FormGroup :model="playerBioDet" :allReadonly="isReadOnlyAccess" :schema="schema" :layout="layout" @change="onChange" />

            <div class="dialog-footer">
                <el-button type="primary" @click="onSave" :disabled="!bioDetFound || !playerBioDet.isValidForEdit() || !playerBioDet.hasChanges()"
                    >Save changes</el-button
                >
                <el-button type="primary" @click="onDiscard" :disabled="!bioDetFound || !playerBioDet.isValidForEdit() || !playerBioDet.hasChanges()"
                    >Discard changes</el-button
                >
                <span class="spacer"></span>
                <el-button plain type="danger" v-if="!isReadOnlyAccess" @click="onDelete">Delete Player Biography</el-button>
            </div>
        </div>

        <div v-else>
            <div class="dialog-footer">
                <span class="spacer"></span>
                <el-button plain type="primary" @click="onAdd">Add Player Biography</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerBioDet } from '../models/player-bio-det';
import { deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isReadOnlyAccess } from '../services/authGroups';
import _ from 'lodash';

export default {
    props: ['playerId'],
    data() {
        return {
            schema: PlayerBioDet.schema,
            layout: PlayerBioDet.layout,
            requestError: null,
            isReadOnlyAccess: isReadOnlyAccess(),            
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('playerBioDets', ['playerBioDet', 'loadingBioDet', 'loadingError', 'bioDetFound']),
    },
    methods: {
        ...mapActions('playerBioDets', ['findPlayerBioDet', 'createPlayerBioDet', 'updatePlayerBioDet', 'deletePlayerBioDet']),
        refresh() {
            let self = this;
            this.findPlayerBioDet(this.playerId).catch((error) => {
                if (error.response.data.StatusCode === 404) {
                    return;
                }
                self.requestError = error;
                self.$notify({
                    title: 'Get Player Biography Error',
                    message: error.response.data.Message,
                    type: 'error',
                    duration: 5000,
                });
            });
        },
        onChange() {},
        onAdd() {
            let params = {
                self: this,
                fCreate: this.createPlayerBioDet,
                dto: new PlayerBioDet({ playerNbr: this.playerId }),
                dtoName: 'Player Biography',
                callback: this.crudCallback,
            };
            createConfirmDto(params);
        },
        onSave() {
            let params = {
                self: this,
                fUpdate: this.updatePlayerBioDet,
                dto: this.playerBioDet,
                dtoName: 'Player Biography',
                callback: this.crudCallback,
            };
            updateConfirmDto(params);
        },
        onDiscard() {
            this.playerBioDet.data = _.cloneDeep(this.playerBioDet.original);
        },
        onDelete() {
            let params = {
                self: this,
                fDelete: this.deletePlayerBioDet,
                vConfirm: this.playerId,
                dto: this.playerBioDet,
                dtoName: 'Player Biography',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        clearError() {
            this.requestError = null;
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
    },
};
</script>

<style>
textarea {
    min-height: 84px;
}
</style>
