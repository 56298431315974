<template>
    <div>
        <el-dialog
            :visible.sync="editDialogVisible"
            :close-on-click-modal="false"
            :title="mode === 'edit' ? 'Edit Membership History' : 'Add Membership History'"
            width="65%"
        >
            <FormGroup :model="selectedPlayerMembershipHistory" :schema="playerMembershipHistoriesSchema" :layout="layout" @change="onChange" />
            <div class="dialog-footer">
                <el-button
                    type="primary"
                    @click="saveChanges"
                    :disabled="!selectedPlayerMembershipHistory || !selectedPlayerMembershipHistory.isValid() || !selectedPlayerMembershipHistory.hasChanges()"
                    >Save changes</el-button
                >
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>
                <el-button v-if="mode === 'edit' && !isReadOnlyAccess" type="danger" plain @click="deletePlayerMembershipHistoryClick"
                    >Delete player membership history</el-button
                >
            </div>
        </el-dialog>

        <div class="flex-row mt-0">
            <span class="spacer"></span>
            <el-button v-if="!isReadOnlyAccess" @click="addPlayerMembershipHistory" plain type="primary"> Add player membership history </el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div class="table-container">
            <div class="table-container-box">
                <DataTable
                    v-if="!loadingError"
                    v-loading="loadingPlayerMembershipHistories"
                    :items="playerMembershipHistories.data"
                    :fields="['playerNbr', 'benefitYear', 'membershipType']"
                    :schema="playerMembershipHistoriesSchema"
                    :sortField="options.sortBy"
                    :sortDir="options.sortDir"
                    @header-clicked="setSort"
                    @item-clicked="itemClicked"
                />

                <Paginator
                    v-if="playerMembershipHistories.data"
                    :data="playerMembershipHistories"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerMembershipHistory } from '../models/player-membership-history';
import { isReadOnlyAccess } from '../services/authGroups';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';

export default {
    props: ['playerNbr'],
    data() {
        return {
            playerMembershipHistoriesSchema: PlayerMembershipHistory.schema,
            layout: PlayerMembershipHistory.layout,
            editDialogVisible: false,
            selectedPlayerMembershipHistory: null,
            mode: null,
            requestError: null,
            isReadOnlyAccess: isReadOnlyAccess(),

            options: {
                queryString: null,
                sortBy: 'playerNbr',
                sortDir: 'descending',
                page: 0,
                pageSize: 20,
                playerNbr: this.playerNbr,
            },
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('playerMembershipHistories', ['playerMembershipHistories', 'loadingPlayerMembershipHistories', 'loadingError']),
    },
    methods: {
        ...mapActions('playerMembershipHistories', [
            'findPlayerMembershipHistories',
            'createPlayerMembershipHistory',
            'updatePlayerMembershipHistory',
            'deletePlayerMembershipHistory',
        ]),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerMembershipHistories,
                options: this.options,
                dtosName: 'Player Membership Histories',
            };
            retrieveDtos(params);
        },
        itemClicked(item) {
            this.selectedPlayerMembershipHistory = new PlayerMembershipHistory(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        addPlayerMembershipHistory() {
            this.selectedPlayerMembershipHistory = new PlayerMembershipHistory({
                playerNbr: this.playerNbr,
            });
            this.mode = 'add';
            this.editDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createPlayerMembershipHistory,
                    dto: this.selectedPlayerMembershipHistory,
                    dtoName: 'Player Membership History',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            } else {
                let params = {
                    self: this,
                    fUpdate: this.updatePlayerMembershipHistory,
                    dto: this.selectedPlayerMembershipHistory,
                    dtoName: 'Player Membership History',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            }
        },
        onChange() {},
        deletePlayerMembershipHistoryClick() {
            let params = {
                self: this,
                fDelete: this.deletePlayerMembershipHistory,
                vConfirm: this.playerNbr,
                dto: this.selectedPlayerMembershipHistory,
                dtoName: 'Player Membership History',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        clearError() {
            this.requestError = null;
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>

<style scoped>
.table-container {
    max-width: 1000px;
}
</style>
